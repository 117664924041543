import React from "react";
import styled from "styled-components";

const Container = ({ children, className }) => {
  return <Containers className={className}>{children}</Containers>;
};

const Containers = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 125rem;
  margin: 0 auto;
  padding: 0 1.5em;
`;

export default Container;
