import React from "react";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import styled from "styled-components";
import UkIcon from "../images/assets/uk-flag.svg";
import FranceIcon from "../images/assets/france-flag.svg";

const LanguageSelector = ({ siteTitle }) => {
  const { languages, originalPath, t } = useI18next();

  return (
    <Lang>
      {languages.map((lng) => {
        const getIcon = (lng) => {
          switch (lng) {
            case "en":
              return (
                <div>
                  <UkIcon /> <span>English</span>
                </div>
              );
            case "fr":
              return (
                <div>
                  <FranceIcon />
                  <span>French</span>
                </div>
              );
            default:
              return null;
          }
        };
        return (
          <li key={lng}>
            <Link to={originalPath} language={lng}>
              {getIcon(lng)}
            </Link>
          </li>
        );
      })}
    </Lang>
  );
};

const Lang = styled.ul`
  display: flex;
  li:not(:last-child) {
    margin-right: 1em;
  }
  svg {
    width: 2em;
  }
  div {
    display: flex;
    align-items: center;
    span {
      margin-left: 0.5em;
      transition: color 0.3s ease-out;
      &:hover {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`;

export default LanguageSelector;
